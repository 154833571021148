import './Toaster.css';
import React, { useState } from 'react';
import { Box, Popover, Popper } from '@mui/material';

function Toaster({message, anchorElement, handleClearToaster, isBlocking}) {
    const [open, setOpen] = useState(false);
    
    if (message !== "" && !open) {  
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
            handleClearToaster();
        }, 3000);
    }

    return (
        <>
        <Popper className={isBlocking ? 'hidden' : ''}
            id={message}
            open={open}
            anchorEl={anchorElement}
            placement='bottom'
            >
            <Box className="toasterText">{message}</Box>
        </Popper>
        <Popover className={isBlocking ? '' : 'hidden'}
            id={message}
            open={open}
            anchorEl={anchorElement}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
            <Box className="toasterText">{message}</Box>
        </Popover>

        </>
    );
  }

  export default Toaster;