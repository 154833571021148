import './AppPages.css'

import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuickHelp from './QuickHelp';


function ModalHelp({ appData, handleClose, handleMenuClick }) {
    const handleFulllHelp = () => {
        handleMenuClick();
    }

    return (
        <Dialog
            open={appData.currentAppPage === 'help'}
            onClose={handleClose}
            className="modal"
            >
            <DialogTitle className="modalHeader">
                <Box>
                    <Box >How to play Rootl</Box>
                    <Box>
                        <IconButton className="closeButton" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

            </DialogTitle>
            <DialogContent className='modalContent'>
                <Box>&nbsp;</Box>
                <QuickHelp />
                <div className='actions'>
                    <Button onClick={handleFulllHelp}>Full rules</Button>
                    <Button variant='contained'
                        onClick={handleClose}>
                            Play game
                    </Button>
                </div>

                
            </DialogContent>
            </Dialog>
    );
}

export default ModalHelp;