
import { Button, ButtonGroup } from '@mui/material';
import GamesIcon from '@mui/icons-material/Games';
  
function LevelMenu({gameData, levelChangeHandler }) {
  const levelMenuData = [];

  for (let a = 0; a < gameData.levelData.length; a++) {
    let successClass = "primary";
    let variant = "outlined";
    if (gameData.levelData[a] !== undefined) {
        if (gameData.levelData[a].success === 1) {
            successClass = 'error';
            variant = "contained";
        } else if (gameData.levelData[a].success === 2) {
            successClass = 'success';
            variant = "contained";
        }
    }

    levelMenuData.push(
        <Button key={a} value={a} 
        color={successClass}
        variant={variant}
        startIcon={gameData.currentLevelId === a ? <GamesIcon/> : ""}
        onClick = {() => levelChangeHandler(a)}
        aria-label={"Level " + (a+1)}>{a+1}</Button>
    );
  }

return (
    <ButtonGroup className="levelSelector" variant=""
        aria-label="Level selector" >
        {levelMenuData}
    </ButtonGroup>
)};

export default LevelMenu;
