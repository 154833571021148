export const getFoundLetters = (answer, guesses) => {
    const allLetters = guesses.join("");
    let retv = "";
    for (let a = 0; a < answer.length; a++) {
        const letter = answer[a];
        if (allLetters.indexOf(letter) > -1) {
            retv += letter;
        } else {
            retv += " ";
        }
    }
    return retv;
}

export const getDisplayLetters = (foundLetters, mode, success) => {
    let displayLetters = foundLetters

    // In "Beast" mode, we simply show the letters the user has guessed, 
    // with no indication of order or how many
    if (mode === "beast" && success === 0) {
        displayLetters = displayLetters.replace(/\s/g, '');
        let displayArray = displayLetters.split("").sort();
        let filteredArray = displayArray.filter((item, index) => displayArray.indexOf(item) === index);
        displayLetters = filteredArray.join("");

    // In "Challenge" mode, we strip out spaces
    } else if (mode === "challenge") {
        displayLetters = displayLetters.replace(/\s/g, '');
    }

    // In "challenge" and "beast" mode, the stripping operation may result in an empty string, 
    // so let's provide something to show the user.
    if (displayLetters.length === 0) {
        displayLetters = "..."
    }
    return displayLetters;
}

export const getGameHistory = (levelData) => {
    let resultString = "";

    for (let a = 0; a < levelData.length; a++) {
        const level = levelData[a];
        const guessList = [];
        for (let b = level.guesses.length-1; b >= 0; b--) {
            const guess = level.guesses[b];
            guessList.push(guess);
            resultString += getFoundLetters(level.answer, guessList, "normal") + "\n";
        }
        resultString += "\n";
    }
    return resultString;
}

export const getMissedLetters = (answer, guesses) => {
    // Strip any special characters (such as the take-a-hint token)
    let allLetters = guesses.join("").replace(/[^A-Z-]/g, "").split("");
    // Filter out letters that are in the answer
    allLetters = allLetters.filter((item) => {
      return answer.indexOf(item) < 0;
    });
    // Now filter out the duplicates
    allLetters = allLetters.filter((item, index) => allLetters.indexOf(item) === index);
    return allLetters.sort();
}

export const letterGuessedAtTurn = (letter, guesses) => {
    for (let a = guesses.length-1; a > -1; a--) {
        const guess = guesses[a];
        if (guess.indexOf(letter) > -1) {
            return guesses.length - (a+1);
        }
    };
    return -1;
}

export const buildMatchesArray = (guess, answer) => {
    const retv = [...guess].map((letter, a) => {
        const regexA = RegExp(letter, "g");
        const occurencesInAnswer = [];
        
        while(regexA.exec(answer) != null ) {
            occurencesInAnswer.push(regexA.lastIndex-1);
        }
        
        const regexG = RegExp(letter, "g");
        const occurencesInGuess = [];
        while(regexG.exec(guess) != null ) {
            occurencesInGuess.push(regexG.lastIndex-1);
        }
        const diff = occurencesInAnswer.length - occurencesInGuess.length;

        // which occurence am I?
        const whichOccurence = occurencesInGuess.findIndex(element => element === a);

        let outcome;
        let instances = [];
        // Not found
        if (answer.indexOf(letter) === -1) {
            outcome = "reject";
            instances = [-1];
        }

        // First match occurrence (create duplicates as necessary)
        else if (guess.indexOf(letter) === a && diff > 0) {
            outcome = "match";
            for (let b = 0; b < diff+1; b++) {
                instances.push(occurencesInAnswer[b]);              
            }
        }

        // Spares (matches, but more than we need)
        else if (whichOccurence >= occurencesInAnswer.length) {
            outcome = "spare";
            instances = [-1];
        }

        // Any other repeated occurrence
        else {
            outcome = "match";
            instances.push(occurencesInAnswer[whichOccurence]);  
        }

        return {
            outcome: outcome,
            letter: letter,
            instances: instances,
        }
        
    })
    return retv;
}