export const calculateScore = (step, scoreArray) => {
    return scoreArray[step-1] ?? 0;
  }

export const currentGameState = (levelData, appData) => {
  let count = 0;
  let wins = 0;
  let score = 0;
  let quitLevels = 0;
  let expendedLevels = 0;
  const scores = [];
  const incompleteLevels = [];
  
  for (let a = 0; a < levelData.length; a++) {
    const element = levelData[a];
    let levelScore = 0;
    if (element.success > 0) {
      count ++;
    } else {
      incompleteLevels.push(a);
    }
    if (element.success === 2) {
      wins ++;
      levelScore = calculateScore(levelData[a].guesses.length, appData.scoreArray)
      score += levelScore;
    } else if (element.success === 1) {
      //They lost...but why?
      const quitLevel = appData.allowedGuesses > levelData[a].guesses.length;
      if (quitLevel) {
        quitLevels ++;
      } else {
        expendedLevels ++;
      }
    }
    scores[a] = levelScore;
  }

  return {
    count: count,
    wins: wins,
    quitLevels: quitLevels,
    expendedLevels: expendedLevels,
    score: score,
    scores: scores,
    isComplete: levelData.length > 0 && count === levelData.length,
    incompleteLevels: incompleteLevels
  }
}

export const nextUnfinishedLevel = (levelData, appData) => {
  return currentGameState(levelData, appData).incompleteLevels[0];
}