// Cookie structure:
// gameId&success:guess,guess,guess:answer:showHint|success:guess,guess,guess:answer:showHint|success:guess,guess,guess:answer:showHint
// Example:
// 13&1:this,that,other:ANSWER:1|0:me,%,theirs:ANSWER:1|2:doc,cat,person:ANSWER:0
//
// Breaking that apart:
// 13 is the *gameId*
// & is a separator
// everything after this is an array of levels, separated by |
// 1 0 and 2 are *success* states (0=incomplete, 1=fail, 2=success)
// : separates the outcomes, guesses, answers, and the showHint choice
// this, that, other, etc are the *guessed words*, separated by commas
// Note the % ... this is a special case of a hint taken, and therefore a turn skipped
// ANSWER is the correct answer for the level
// 0 at the end indicates not hint has been revealed. 1 indicates it has. 

// Returned object
// {
//    gameId: 0,
//    levelData: [
//        answer: "ANSWER",
//        success: 0|1|2,
//        showHint: true|false
//        guesses: [words]
//      }  
//    ]
//}

// Use this if we receive anything nullish
const stringedIdentity = "0&0::W|0::W|0::W";

export const mungeGameData = (objectToMunge) => {
  let retv = objectToMunge.gameId + "&";
  let levelDataArray = [];
  for (let a = 0; a < objectToMunge.levelData.length; a++) {
    const element = objectToMunge.levelData[a];
    const showHint = element.showHint ? "1" : "0";
    levelDataArray.push(element.success + ":" + element.guesses.join(",") + ":" + element.answer + ":" + showHint);
  }
  retv += levelDataArray.join("|");
  return retv;
} 

export const demungeGameData = (existingGameData) => {
  const readData = existingGameData ?? stringedIdentity;
  const firstSplit = readData.split("&");
  const readGameId = Number(firstSplit[0]);
  const readGameData = firstSplit[1];
  const readAllLevelData = readGameData.split("|");

  let levelData = [];
  for (let a = 0; a < readAllLevelData.length; a++) {
    const element = readAllLevelData[a];
    const readLevelData = element.split(":");
    const guesses = readLevelData[1] === "" ? [] : readLevelData[1].replace(/[\^!]/, "%").split(",");

    levelData[a] = {
      success: Number(readLevelData[0]),
      guesses: guesses,
      answer: readLevelData[2],
      showHint: readLevelData[3] === "1"
    }
  }

  const retv = {
    gameId: readGameId,
    levelData: levelData
  };
  return retv;
}