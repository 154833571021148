import './Clue.css'
import { Link, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import sanitizeHtml from 'sanitize-html';

function Clue({ appData, gameData,handleHintButtons }) {
    const levelData = gameData.levelData[gameData.currentLevelId];

    const sanitize = (dirty) => ({
        __html: sanitizeHtml(
          dirty,
          {
            allowedTags: ['b', 'i', 'em', 'br'],
          }
        )
      });


    return (
        <div className='clue'>

            <h3 className='title'>Derived from &nbsp;
            <Tooltip title="What the word meant in some prior form BEFORE it acquired its current meaning" placement='top' arrow enterTouchDelay={0}>
            <InfoOutlinedIcon fontSize="x-small" /></Tooltip>
            </h3>
            <h2 className="derivedMeaning">
                <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} dangerouslySetInnerHTML={sanitize(levelData.derivedMeaning)}>
                </Typography>
            </h2>

            <h3 className='title'>How it came to English &nbsp;
            <Tooltip title="The journey the secret word took on its way to modern English" placement='top' arrow enterTouchDelay={0}>
            <InfoOutlinedIcon fontSize="x-small" /></Tooltip>
            </h3>
            <h2 className="derivation">
                <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                    {levelData.derivation}
                </Typography>
            </h2>

            <h3 className='title'>Part of speech &nbsp;
            <Tooltip title="The part of speech (noun, verb, adjective, etc.) of the secret word as it is used in modern English" enterTouchDelay={0}
                placement='top' arrow>
            <InfoOutlinedIcon fontSize="x-small" /></Tooltip></h3>
            <h2 className="partOfSpeech">
                <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                    {levelData.partOfSpeech}
                </Typography>
            </h2>


            <h3 className={appData.mode === 'normal' ? 'title' : 'hidden'}>
                <Link className={levelData.showHint || levelData.success > 0 ? 'hidden' : ''}
                    value="hint" key="hint" color="secondary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleHintButtons('hint')}>
                    Show hint
                </Link>
            </h3>
            <h3 className={levelData.showHint || levelData.success > 0 ? 'title' : 'hidden'}>Hint</h3>
            <h2 className={levelData.showHint || levelData.success > 0 ? 'hint' : 'hidden'}>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} dangerouslySetInnerHTML={sanitize(levelData.hint)}>
                </Typography>
            </h2>
        </div>
    );
}

export default Clue;