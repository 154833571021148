import './GuessList.css';

function GuessList({ appData, gameData , handleSelection}) {

    const guesses = [];
    for (let a = 0; a < appData.allowedGuesses; a++) {
        let element = gameData.levelData[gameData.currentLevelId].guesses[a];
        if (element === undefined) {
            element =  "\u00A0";
        } else if (element.search(/[\^!%]/) > -1) {
            // "%" is a magic character indicating the user has taken a hint (and therefore lost a turn)
            element = "[Took a hint]"
        }

        guesses.push(<div key={a}
            onClick={handleSelection}>{element}</div>);
    }

    let warningClass = "";
    const remainingGuesses = appData.allowedGuesses - gameData.levelData[gameData.currentLevelId].guesses.length;
    if (remainingGuesses <= appData.dangerStep) {
        warningClass = "danger";
    } else if (remainingGuesses <= appData.warningStep) {
        warningClass = "warning";
    }

    return (
        <div className='guessList'>
            <h1>Guess list</h1>
            <h2 className={warningClass} >Guesses remaining: {remainingGuesses}</h2>
            <ul>{guesses}</ul>
        </div>
    );
  }
  
  
  export default GuessList