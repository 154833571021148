import { Button, Typography } from '@mui/material';
import './OutcomeScreen.css';
import { calculateScore } from '../../../Utilities/ScoreUtility';
import sanitizeHtml from 'sanitize-html';
  
function OutcomeScreen({ appData, gameData }) {
    const answer = gameData.levelData[gameData.currentLevelId].answer;
    const success = gameData.levelData[gameData.currentLevelId].success;
    const interesting = gameData.levelData[gameData.currentLevelId].interesting;
    const score = calculateScore(gameData.levelData[gameData.currentLevelId].guesses.length, appData.scoreArray);
    
    const sanitize = (dirty) => ({
        __html: sanitizeHtml(
          dirty, 
          {
            allowedTags: ['b', 'i', 'em', 'a', 'br'],
            allowedAttributes: {
              a: ['href', 'target']
            }
          }
        )
      });


    const getClassName = () => {
        let retv = "";
        switch (success) {
            case 1:
                retv = 'outcome defeat';
                break;
            case 2:
                retv = 'outcome victory';
                break;
            default:
                retv = 'hidden';
        }
        return retv;
    }
    return (
    <div className={getClassName() }>
        
            
                <Typography className={success === 2 ? '' : 'hidden'} size="small" color="text.secondary" gutterBottom>
                    Well done! You have correctly guessed
                </Typography>
                <Typography className={success === 1 ? '' : 'hidden'} size="small" color="text.secondary" gutterBottom>
                    Bad luck! The word was
                </Typography>
                <Typography variant="h5" component="div">
                    {answer}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={success === 1 ? 'hidden' : ''}>
                    For {score} points
                </Typography>
                <Typography sx={{margin:"1rem 0 1rem"}} size="small" color="text.secondary" dangerouslySetInnerHTML={sanitize(interesting)}>
                </Typography>
                <Button href={"https://www.etymonline.com/search?q=" + answer.toLowerCase()} 
                    target='_blank' 
                    size="small" variant="contained">Etymology for {answer}</Button>
            
        
    </div>
    );
  }

  export default OutcomeScreen;