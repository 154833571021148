import './AppPages.css'
import { Button, Link, Typography } from '@mui/material';
import QuickHelp from './QuickHelp';


function Help({ appData, gotoGameHandler }) {
    


    return (
        <div className={appData.currentAppPage === 'fullHelp' ? 'appPage help' : 'hidden'}>
            <Button style={{margin:'0 .5rem'}} variant="contained" onClick={gotoGameHandler}>
                <Typography variant="button">PLAY GAME</Typography>
            </Button>
             <h1 >
                How to play Rootl
            </h1>
            <QuickHelp />

            <h1>Understanding Rootl</h1>
            <div>
                <div>Rootl is a game about <em>etymology</em>, which is just a fancy way of saying 'where words come from.' There's a lot of fascinating meaning hidden in the history of the words we use. Most English words are derived from other languages, but some are combinations of other words and some (like Rootl!) are just made up.</div>
                <div>In this game, I give you the source of an English word and you try to guess it.</div>

                <h2>The clues</h2>
                <div>A typical clue looks something like this:</div>
                <img src={require('../../Images/clue_explained.png')} alt='Typical Rootl clue' />
                <div>The clue has three parts.</div>
                <h3>Derived from</h3>
                <div>This tells you something about some <em>older meaning</em> that the word or its ancestors had. For example, the word 'ETYMOLOGY' comes from two roots meaning 'true meaning' and 'study' or 'science'.</div>
                <div>In order to play Rootl well, it's important to understand that as words drift over time, meanings can change a lot. So this derivation may or may not be especially close to what the word means today. Also, history isn't always an exact science, and some etymologies are disputed. I try to point this out when I provide such a word.</div>
                <h3>How it came to English</h3>
                <div>The second clue tells you the language or languages that the word took on its journey to modern English. For example, 'ETYMOLOGY' comes to English from Greek to Latin to French. Again, this can be a point where not all authorities will agree, and again I attempt to point out such disagreements when relevant.</div>
                <h3>Part of speech</h3>
                <div>Finally, you see the part of speech of the word <em>today</em>. This is typically a noun, adjective, verb, or adverb, but may also be less familiar parts of speech like an interjection. For example, the word 'ETYMOLOGY' is a noun.</div>
                <h3>Hints</h3>
                <div>Note the link that says 'Show hint'. I think you can pretty much guess what it'll do. It might come in handy sometimes, but taking a hint will cost you one of your six guesses.</div>
                <img src={require('../../Images/hint.png')} alt='Typical Rootl clue' />
                
                <h2>Making a guess</h2>
                <div>Enter your guess in the text field. All guesses must:</div>
                <ul>
                    <li>Appear in our word list (slightly adapted from the one <Link href='https://github.com/meetDeveloper/freeDictionaryAPI' target='_blank' alt='List of English words'>here</Link>);</li>
                    <li>Be at least 5 letters; and</li>
                    <li>Be no longer than the word you're guessing.</li>
                </ul>


                <h2>Incorrect answers</h2>
                <div>Every incorrect guess will add that guess to the Guess list, as well as indicate which letters were in the correct word (the green tiles in the top row), and which were not (the small, red tiles). Correct letters are added at the top, showing all instances of the letter, in order.</div>
                <img src={require('../../Images/first_guess.png')} alt='The player guesses a word' />
                <div>Notice how the word 'BOTANY' only has one 'O' and one 'Y', but you get credit for multiple appearances (except in <em>Beast Mode!</em>, see below).</div>
                <div>You get {appData.allowedGuesses} tries to guess the mystery word!</div>

                <h2>Scoring</h2>
                <div>It's pretty simple. Fewer guesses means a better score. Each puzzle has a 1000 point maximum. With three puzzles per day, a perfect score is 3000 points.</div>

                <h2>Giving up</h2>
                <div>We all have off days. Consider that the off day in question might have been mine, not yours. If you get too frustrated, there's a Quit button which will forfeit the level and show you the answer. <em>Nil points</em>, I'm afraid.</div>

                <h2>Difficulty modes</h2>
                <h3>Normal</h3>
                <div>In this standard mode, all features, hints, and tools as described above are presented to you.</div>

                <h3>Challenge</h3>
                <div>Challenge mode makes two changes. First, letters appear in the correct order, but without any spaces between them, so it's a lot harder to know how they fit into the answer. Second, I remove the option of a hint.</div>
                <div>Here's how the first guess looks in Challenge mode.</div>
                <img src={require('../../Images/challenge_mode.png')} alt='First guess in Challenge mode' />

                <h3>Beast Mode!</h3>
                <div>For the most hardcore Rootlrs only!</div>
                <div>In this mode, you get no hints, no blanks, and I stop displaying missing letters. And to rub salt into your festering wounds, you'll only see correct letters presented alphabetically, with no indication as to how many appear in the answer.</div>
                <div>Again, here's how the first guess looks, this time in Beast Mode!</div>
                <img src={require('../../Images/beast_mode.png')} alt='First guess in Beast mode' />
                
                <h2>Example game play</h2>
                <img src={require('../../Images/multiple_guesses1.png')} alt='A Rootl of the word etymology, mostly solved.' />
                <div>In the example above, the player has guessed 'BOTANY' and 'TYPOLOGY'.</div>
                <div>The two guessed words appear in the Guess list.</div>
                <div>The letters at the top – [blank] TY [blank] OLOGY – indicate that those letters were guessed AND that they appear within the hidden word in the order displayed.</div>
                <div>All the missed letters appear below the letters that were correctly guessed.</div>
                <div>Of course, if you've read this far, you know that the correct answer is 'ETYMOLOGY'! If you enter that word, you win. But let's say our player still can't guess the correct word. They might hazard another guess, simply to find additional letters. Let's say they try the word 'EMINCENCE'.</div>
                <img src={require('../../Images/win.png')} alt='The player has won!' />
                <div>In this case, the player found all the correct letters. Even though they haven't actually found the word, we still award it as if they have.</div>


                <div>That's all there is to it. Good luck and enjoy!</div>
            </div>
            <Button style={{margin:'0 .5rem'}} variant="contained" onClick={gotoGameHandler}>
                <Typography variant="button">PLAY GAME</Typography>
            </Button>
        </div>
    );
}

export default Help;