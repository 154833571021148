import './AppMenu.css';
import * as React from 'react';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GamesIcon from '@mui/icons-material/Games';
import InfoIcon from '@mui/icons-material/Info';
import NotesIcon from '@mui/icons-material/Notes';
import ShareIcon from '@mui/icons-material/Share';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LaunchIcon from '@mui/icons-material/Launch';
import { Drawer, Icon, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';

function AppMenu({ appData, handleMenuClick, handleToasterMessage, analyticsEvent }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      
      setState({ ...state, [anchor]: open });
  };

  const logClick = (id) => {
    analyticsEvent({
      "event": "visited_" + id,
    });
  }

  const share = (event) => {
    logClick("share");
    navigator.clipboard.writeText("https://www.rootlgame.net/");
    handleToasterMessage("Link copied to clipboard", event.target, true);
  }

  const data = [
    {
      key: "game",
      content: "Game",
      icon: <GamesIcon />,
    },{
      key: "faq",
      content: "FAQ",
      icon: <InfoIcon />,
    },{
      key: "fullHelp",
      content: "Full rules",
      icon: <NotesIcon />,
    }
  ]
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      { data.map((d, index) => {
        return <ListItem key={d.key} disablePadding>
            <ListItemButton onClick={() => handleMenuClick(null, d.key)}
                disabled={d.key === appData.currentAppPage}>
              <ListItemIcon>{d.icon}</ListItemIcon>
              <ListItemText primary={d.content} />
            </ListItemButton>
        </ListItem>

      })}
      </List>
      <Divider />
      <List>
        
          <ListItem key="share" disablePadding>
            <ListItemButton onClick={share}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText primary="Tell your friends!" />
            </ListItemButton>
          </ListItem>
          <ListItem key="threads" disablePadding>
            <ListItemButton onClick={() => logClick("threads")} href="https://www.threads.net/@rootlgame" target="_blank">
              <ListItemIcon>
                <Icon><img alt="Threads logo" src={require('../../Images/threads_logo.png')} height={24} width={24} /></Icon>
              </ListItemIcon>
              <ListItemText primary="Follow on Threads" />
            </ListItemButton>
          </ListItem>
          <ListItem key="facebook" disablePadding>
            <ListItemButton onClick={() => logClick("facebook")} href="https://www.facebook.com/rootlgame" target="_blank">
              <ListItemIcon>
                <Icon><img alt="Facebook logo" src={require('../../Images/Facebook_Logo_Secondary.png')} height={24} width={24} /></Icon>
              </ListItemIcon>
              <ListItemText primary="Follow on FB" />
            </ListItemButton>
          </ListItem>
          <ListItem key="blog" disablePadding>
            <ListItemButton onClick={() => logClick("blog")} href="https://fogandwhisky.com/blog/" target="_blank">
              <ListItemIcon>
                <RssFeedIcon />
              </ListItemIcon>
              <ListItemText primary="My blog" />
            </ListItemButton>
          </ListItem>
          <ListItem key="feedback" disablePadding>
            <ListItemButton onClick={() => logClick("feedback")} href="https://forms.gle/vuNZR2T5Datspa2d8" target="_blank">
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback" />
            </ListItemButton>
          </ListItem>
          <ListItem key="coffee" disablePadding>
            <ListItemButton onClick={() => logClick("donate")}  href="https://www.buymeacoffee.com/rootl" target="_blank">
              <ListItemIcon>
                <LocalCafeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Buy me a coffee" />
            </ListItemButton>
          </ListItem>
          <ListItem key="email" disablePadding>
            <ListItemButton onClick={() => logClick("mailing")}  href="https://forms.gle/y4hfjnq43u614TuC9" target="_blank">
              <ListItemIcon>
                <EmailOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Sign up for daily reminders" />
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );

  return (
        <div key="appMenu" className="appMenu">
          <IconButton className="appMenuTrigger" onClick={toggleDrawer("left", true)}><MenuIcon /></IconButton>
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </div>
      
    
  );
}

export default AppMenu;