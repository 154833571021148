import { useEffect, useRef } from 'react';
import { TextField } from "@mui/material";
import './TextInput.css';

function TextInput ({gameData, cookie, guessTextField, handleToasterMessage, handleNewGuess}) {
    const dict = useRef([]);

    useEffect(() => {
        fetch('./data/dict_stripped.txt')
        .then((res) => res.text())
        .then((text) => {
            dict.current = text.split("\n");
        })
        .catch((e) => console.error(e));
    }, [dict]);

    const keyboardChange = (event) => {
        // Force to uppercase and remove stray spaces
        let value = event.target.value.toUpperCase().replace(/[\s]/g, '');

        // If disallowed character, warn user
        if (value.search(/[^-A-Z\s]/g) > -1) {
            handleToasterMessage("Only the letters A-Z and hyphens are permitted in guesses.", document.getElementById("input-textfield"));
        }
        // If exceeding length, warn  user
        else if (value.length > gameData.levelData[gameData.currentLevelId].answer.length) {
            handleToasterMessage("You're not allowed to guess words longer than the answer.", document.getElementById("input-textfield"));
        }
    }

    const evaluateGuess = (event) => {
        //Process this only if the enter key was hit
        if (event.key !== "Enter"){
            return;
        }

        // Force to uppercase and remove any stray spaces 
        var guessText = event.target.value.toUpperCase().replace(/[\s]/g, '');
        const level = gameData.levelData[gameData.currentLevelId];

        if (guessText==="GAWAINE") {
            console.log("Your cookie", cookie);
        }

        let rejected = false;
        let msg = "";

        // Reject if illegal characters
        if (guessText.search(/[^-A-Z]/g) > -1) {
            msg = "Only the letters A-Z and hyphens are permitted in guesses.";
            rejected = true;
        }
        // Reject if too long
        else if (guessText.length > level.answer.length) {
            msg = "You're not allowed to guess words longer than the answer.";
            rejected = true;
        }
        // Reject the guess if they've already guessed this word
        else if (level.guesses.indexOf(guessText) > -1) {
            msg = "You've already guessed this word.";
            rejected = true;
        }
        // Reject if too short
        else if (guessText.length < 5) {
            msg = "Rootl words are all between 5 and 15 letters long.";
            rejected = true;
        }
        // Reject if longer than the answer
        else if (guessText.length > gameData.levelData[gameData.currentLevelId].answer.length) {
            msg = "You're not allowed to guess words longer than the answer.";
            rejected = true;
        }
        // Reject if not in dictionary
        else if (dict.current.indexOf(guessText.toLowerCase()) === -1) {
            msg = guessText + " is not in the recognized word list.";
            rejected = true;
        }

        if (rejected) {
            handleToasterMessage(msg, document.getElementById("input-textfield"));
        } else {
            handleNewGuess(guessText);
        }

        event.target.value = "";
    }

    return (
        <TextField className="guessSpace" id="input-textfield" label="Guess the word..." 
            variant="filled" 
            onKeyUp={evaluateGuess}
            onInput={keyboardChange}
            inputRef={guessTextField}
            disabled={gameData.levelData[gameData.currentLevelId].success > 0}
            autoComplete='off'
            type="text"
        />
    )
}

export default TextInput;