import React, { useRef, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Paper, Select, Button, Tooltip } from '@mui/material';
import  './Level.css';
import Clue from './Clue/Clue';
import TextInput from './TextInput/TextInput';
import GuessList from './GuessList/GuessList';
import OutcomeScreen from './OutcomeScreen/OutcomeScreen';
import FoundLetters from './ResultSpace/FoundLetters';
import Challenge from '../Challenge/Challenge';
import MissedLetters from './ResultSpace/MissedLetters';
import { currentGameState, nextUnfinishedLevel } from '../../Utilities/ScoreUtility';
import LetterDeployment from './ResultSpace/LetterDeployment';

function Level ({appData, gameData, cookie,
        handleNewGuess, handleQuit,
        handleShowHint, handleModeChange, handleToasterMessage,
        levelChangeHandler,
        analyticsEvent}) {

  const guessTextField = useRef(null);

  const [timingConfig, setTimingConfig] = useState({
    initDelayMS: 1000,
    perLetterDelayMS: 200,
    perLetterAnimMS: 500, 
    totalWaitMS: 0
  });
  
  const challengeIdentity = {
    message: "",
    title: ""
  };
  const [challengeData, setChallengeData] = useState(challengeIdentity);

  const handleHintButtons = (value) => {
    const quitLevel = () => {
      handleQuit();
    }
    const showHint = () => {
      // Display the hint
      analyticsEvent({
        "event": "hint_displayed",
        "guesses": gameData.levelData[gameData.currentLevelId].guesses.length
      });
      handleShowHint();
    }

    const challengeQuit = () => {
      setChallengeData({
        message: "Are you sure you want to quit this level? You'll see the answer but won't get any points. (For daily games, this will break your win streak!)",
        title: "This will forfeit the current level.",
        confirmHandler: quitLevel,
        id: "quit_level"
      });
    }
    const challengeHint = () => {
      setChallengeData({
        message: "Are you sure you want to see a hint?",
        title: "This will cost 1 turn.",
        confirmHandler: showHint,
        id: "show_hint"
      });
    }
    switch(value) {
        case 'hint':
          challengeHint();
          analyticsEvent({
            "event": "hint_clicked",
            "guesses": gameData.levelData[gameData.currentLevelId].guesses.length
          });
          break;
        case 'quit':
          challengeQuit();
          break;
        case 'nextLevel':
          const levelId = nextUnfinishedLevel(gameData.levelData, appData);
          levelChangeHandler(levelId);
          break;
        default:
          break;
    }
  }

  const handleSelection = (event) => {
    guessTextField.current.focus(); 
  }

  const handleGuess = (guess, asQuit) => {
    const time = timingConfig.initDelayMS + (guess.length * timingConfig.perLetterDelayMS) + timingConfig.perLetterAnimMS;
    timingConfig.totalWaitMS = time;
    setTimingConfig(timingConfig);
    setTimeout (() => {
      timingConfig.totalWaitMS = 0;
      setTimingConfig(timingConfig);
    }, time);
    handleNewGuess(guess, asQuit);
  }

  ///
  /// DOM RENDER
  ///
  return (
  <Paper className='game' elevation={6}>
    
    <Challenge title={challengeData.title} message={challengeData.message} id={challengeData.id}
      confirmHandler={challengeData.confirmHandler} handleClearChallenge={() => {setChallengeData(challengeIdentity)}} />
    <div className='gameOptions'>

        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel>Difficulty</InputLabel>
          <Select
            value={appData.mode}
            variant="outlined"
            label="Difficulty"
            onChange={handleModeChange}
          >
            <Tooltip title='All hints and clues available.' placement='right' arrow value="normal">
              <MenuItem>
                Normal
              </MenuItem>
            </Tooltip>
            <Tooltip title='Hints and blanks hidden.' placement='right' arrow value="challenge">
              <MenuItem>
                Challenge
              </MenuItem>
            </Tooltip>
            <Tooltip title='Hints, blanks, missed letters hidden. Found letters shown in alphabetical order; no indication of duplicates.' placement='right' arrow value="beast">
              <MenuItem >
                Beast Mode!
              </MenuItem>
            </Tooltip>
          </Select>
        </FormControl>

{ gameData.levelData[gameData.currentLevelId].success === 0 ?

      // <Tooltip title="I'm stumped. Show me the answer!" placement='top-end' arrow enterTouchDelay={0}>
        <Button value="quit" 
            size="medium" color="error" variant='contained'
            onClick={() => handleHintButtons('quit')}>
            <div>Quit</div>
        </Button>
      // </Tooltip>

    : '' }
{ gameData.levelData[gameData.currentLevelId].success > 0 && !currentGameState(gameData.levelData, appData).isComplete ?
      <Tooltip title="Take me to the next puzzle" placement='top-end' arrow enterTouchDelay={0}>
        <Button value="next level" 
            size="medium"
            color="success"
            variant="contained"
            onClick={() => handleHintButtons('nextLevel')}>
            <div>Next</div>
        </Button>
      </Tooltip>
  : '' }
    </div>

    <FoundLetters appData={appData} gameData={gameData} handleSelection={handleSelection} revealDelayMS={timingConfig.totalWaitMS} />
    <MissedLetters appData={appData} gameData={gameData} handleSelection={handleSelection} revealDelayMS={timingConfig.totalWaitMS} />

  <div className='textInputAndDeployment'>
    <LetterDeployment appData={appData}
        gameData={gameData}
        timingConfig={timingConfig} />
    <TextInput gameData={gameData}
        cookie={cookie}
        guessTextField={guessTextField}
        handleToasterMessage={handleToasterMessage}
        handleNewGuess={handleGuess} />
  </div>

    <OutcomeScreen appData={appData} gameData={gameData} />
    
    <div className='clueAndGuessList'>
      <Clue appData={appData} gameData={gameData} handleHintButtons={handleHintButtons} analyticsEvent={analyticsEvent} />
      <GuessList appData={appData} gameData={gameData} handleSelection={handleSelection} />
    </div>
  </Paper>
  )
}

export default Level;