import React, { useState } from 'react';
import Confetti from 'react-confetti';

function CelebrationAnimation({celebrationState, handleClearCelebrationState}) {
  const [open, setOpen] = useState(false);

  const animPropsLevel = {
    num: 75,
    initialVelocityY: 10
  };

  const animPropsGame = {
    num: 300,
    initialVelocityY: 10
  };
  
  const getAnimation = () => {
    const src = {
      x: window.screen.availWidth/2,
      y: window.scrollY,
      width: window.screen.availWidth,
      height: 100
    };
    const animProps = (celebrationState === 2) ? animPropsGame : animPropsLevel;

    return (
      <Confetti
        confettiSource={src}
        width={window.screen.availWidth}
        height={window.screen.availHeight}
        numberOfPieces={animProps.num}
        recycle={false}
        initialVelocityY={animProps.initialVelocityY}
      />
    )
  };

  if (celebrationState !== 0 && !open) {  
      setOpen(true);
      setTimeout(() => {
          setOpen(false);
          handleClearCelebrationState();
      }, 5000);
  }

  return (
    <>
    {open ? getAnimation() : '' }
    </>
    );
}

  export default CelebrationAnimation;