import './GameSelector.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useState } from 'react';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function GameSelector({appData, gameSelectorData, confirmHandler, closeHandler}) {
    
    const [value, setValue] = useState("");


    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        closeHandler();
    };

    const handleConfirm = () => {
        handleClose();
        confirmHandler(value);
    }

    if (gameSelectorData && !open) {  
        setOpen(true);
    }

    const buttonDisabledIf = (value) => {
      // Cheat code for testing
      if (value.substring(0,2) === "!!") {
        return false;
      }
      // Otherwise, it must be a number (so "fail" if not)
      if (isNaN(Math.floor(value))) {
        return true;
      }
      // ...and the number must be within range
      if (value > 0 && value <= appData.currentDate) {
        return false;
      }
      return true;
    }

  return (
      <Dialog className='gameSelector'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="control-dialog-slide-description"
      >
        <DialogTitle>Pick a puzzle to solve (1 through {appData.currentDate})</DialogTitle>
        <DialogContent>
        <TextField id="gameSelection" label="Enter a value" 
            className='gameSelectorText'
            variant="filled" 
            autoComplete='off'
            onChange={(e) => {
                setValue(e.target.value);
            }} />
        </DialogContent>
        <DialogActions>
          <Button id="cancel" onClick={handleClose}>Cancel</Button>
          <Button id="confirm" disabled={buttonDisabledIf(value)}
            color="error" variant='contained'
            onClick={handleConfirm}>Go</Button>
        </DialogActions>
      </Dialog>
  );
}

export default GameSelector;