import { useEffect } from 'react';

    // event: 'customEvent',
    // eventCategory: 'Category',
    // eventAction: 'Action',
    // eventLabel: 'Label',
    // // Additional event values if needed

function GA4({payload, setPayload}) {
    useEffect(() => {
        if (payload) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(
                payload
            );
        }
        setPayload(null);
      }, [payload, setPayload]);
    
    return (
        <>
        </>
    );
  }

  export default GA4;