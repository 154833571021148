import './AppPages.css'
import { Paper, Typography } from '@mui/material';


function QuickHelp() {
    return (
        <>
            <Paper className='quickHelp' elevation={3}>
                <Typography variant="body2" gutterBottom>
                    Guess the word in 6 tries.
                </Typography>
                <Typography variant="body2">
                    Your clues:
                </Typography>
                    <ul>
                        <li>
                            <strong>Derived from</strong>: something the word meant BEFORE its current meaning in English.
                        </li>
                        <li>
                            <strong>How it came to English</strong>: Languages where the word appeared before English.
                        </li>
                        <li>
                            <strong>Part of speech</strong>: how the word functions in English TODAY.
                        </li>
                    </ul>
                <Typography variant="body2" gutterBottom>
                    There are three puzzles every day.<br />Guess them all to win!
                </Typography>
            </Paper>

        </>
    );
}

export default QuickHelp;