import './GameMenu.css';
import { Button, Tooltip, ButtonGroup } from '@mui/material';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import HelpIcon from '@mui/icons-material/Help';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

  
function GameMenu({appData, handleMenuClick}) {

  const data = [
    {
        key: "first",
        disabled: appData.cursorDate <= appData.minDate || appData.currentAppPage !== 'game',
        content: <FirstPageIcon />,
        tooltip: "Play the very first Rootl game"
    },{
        key: "prev",
        disabled: appData.cursorDate <= appData.minDate || appData.currentAppPage !== 'game',
        content: <NavigateBeforeIcon />,
        tooltip: "Play previous day's game"
    },{
        key: "help",
        disabled: false,
        content: <HelpIcon />,
        tooltip: "How to play"
    },{
        key: "stats",
        disabled: false,
        content: <LeaderboardIcon />,
        tooltip: "Your Rootl stats"
    },{
        key: "next",
        disabled: appData.cursorDate >= appData.currentDate || appData.currentAppPage !== 'game',
        content: <NavigateNextIcon />,
        tooltip: "Play next day's game"
    },{
        key: "last",
        disabled: appData.cursorDate >= appData.currentDate || appData.currentAppPage !== 'game',
        content: <LastPageIcon />,
        tooltip: "Play today's game"
    }
    ];

    const list = data.map((d, index) => {
        return <Tooltip key={d.key}  title={d.tooltip} placement='bottom' arrow enterTouchDelay={0}>
            <span>
                <Button value={d.key} aria-label={d.tooltip} 
                    onClick={() => handleMenuClick(null, d.key)}
                    disabled={d.disabled} variant="normal"> 
                    <span>{d.content}</span>
                </Button>
            </span>
        </Tooltip>
    });

    const getIsGame = () => {
        return appData.currentAppPage === "game" ||
            appData.currentAppPage === "help" ||
            appData.currentAppPage === "stats";
    }

return (
    <div className = {getIsGame() ? "gameMenu" : "hidden"}>
        <ButtonGroup aria-label="Game menu selector" >
            {list}
        </ButtonGroup>
    </div>
    );
  }

  export default GameMenu;