import './Score.css'
import { calculateScore } from '../../../Utilities/ScoreUtility'

function Score ({appData, gameData}) {
    
    let score = 0;
    for (let a = 0; a < gameData.levelData.length; a++) {
        if (gameData.levelData[a].success === 2) {
            score += calculateScore(gameData.levelData[a].guesses.length, appData.scoreArray);
        }
    }
    return (
        <div className="score">Score: {score}</div>
    )
}

export default Score;