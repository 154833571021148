import { Link } from '@mui/material';
import { Button, Typography } from '@mui/material';
import './AppPages.css'

function Faq({appData, handleMenuClick}) {

    return (
        <div className={appData.currentAppPage === 'faq' ? 'appPage faq' : 'hidden'}>
            <Button style={{margin:'0 .5rem'}} variant="contained" onClick={() => handleMenuClick(null, "game")}>
                <Typography variant="button">PLAY GAME</Typography>
            </Button>
            <h1>Questions (and answers) about Rootl</h1>

            <h2>How can I report a bug or error, or otherwise ask questions about Rootl?</h2>
            <div>Please use <Link href='https://forms.gle/vuNZR2T5Datspa2d8' target='_blank'>this form</Link>.</div>

            <h2>What data does Rootl collect on me?</h2>
            <div>I use web analytics to capture basic game play information for the sole purpose of improving the game. Examples of what I collect include page views, level and game completion rates, and clicks on certain buttons. This data does not include any personally identifiable information whatsoever. All data regarding your score and other gameplay information (such as how often you play) is stored on your device. You can delete this information at any time from the <Link onClick={() => handleMenuClick(null, "stats")}>Stats page</Link>.</div>
            <div>The Rootl privacy policy can be found <Link href="https://www.freeprivacypolicy.com/live/c24f625b-33a5-42b7-b501-4eb03012f622" target="_blank">here</Link>.</div>

            <h2>Who's responsible for this?</h2>
            <div>That'd be me, Marc Tanenbaum. I've always been a bit of a nerd for language, and fascinated by the origins of and connections between words. This game is my idea, the coding and design are all mine as well. (My wife helped a bit with the colors!)</div>
            <div>I should add a few tips of the hat to folks who helped:</div>
            <ul>
                <li>My word list comes from meetDeveloper's <Link href='https://github.com/meetDeveloper/freeDictionaryAPI' target='_blank' alt='List of English words'>Free Dictionary API</Link>  (though I don't use the API itself).</li>
                <li>While I research many sources, I get a lot of my information from the <Link href='https://www.etymonline.com/' target='_blank' alt='The Online Etymology Dictionary' >Online Etymology Dictionary</Link>.</li>
                <li>Thanks to my friend Laurel, and my wife Orla, who played the very first test rounds of Rootl to help me work out if it was fun.</li>
                <li>Thanks to my buddy Paul, who came up with the name! (And assisted in no small way with DevOps)</li>
            </ul>

            <h2>Can I get some hints on how to play?</h2>
            <div>Other than reading the rules, here are some thoughts on how to think about the puzzles.</div>
            <ul>
                <li>The 'derived from' definition is NOT what the word means today. It might not even be the same part of speech. Language works like that: sometimes nouns become verbs ('to hoover one's apartment') or the meaning of a word inverts entirely ('literally!'). 'Derived from' is what the word <em>used to mean</em> in one of its prior incarnations. Unraveling that maze of meaning is the fun of the game.</li>
                <li>I'm trying to puzzle you, not <em>trick</em> you. I mostly avoid plurals (except where the plural and the singular are the same word, or where the plural form is simply more commonplace). I don't use funny endings. And I don't particularly try to pick hard words. Your vocabulary and mine are probably a bit different, but in general I'm trying to pick words that native English speakers probably know.</li>
                <li>Pay attention to the clue that explains how the word came into English. Greek words have particular patterns, as do German, Latin, Irish, and Japanese. After you've played awhile, you'll begin to spot patterns to improve your guesses.</li>
                <li>Finally, don't be shy about just throwing in a random word, even if it's too short, even if it doesn't come close to any of the clues. It'll probably get you a couple extra letters, and sometimes that's all you'll need to get to the answer.</li>
            </ul>

            <h2>Where do you learn about the history of words?</h2>
            <div>I read a lot, and I like <Link href='https://www.youtube.com/watch?v=K1XQx9pGGd0' target='_blank' alt='The Adventure of English (YouTube)'>documentaries</Link> and <Link href='https://open.spotify.com/show/4YD0tpCpJ9sQNn9HAdgnKf?si=6e005e707e39499f' target='_blank' alt='History of English podcast'>podcasts</Link> about the history of English. For Rootl, I get a lot of my information from the <Link href='https://www.etymonline.com/' target='_blank' >Online Etymology Dictionary</Link>, but also research other sources as I make up each day's game.</div>

            <h2>Will Rootl have ads?</h2>
            <div>Yes. It's how I plan to pay for this.</div>

            <h2>Will you be doing other languages?</h2>
            <div>I'm afraid my language skills aren't up to that challenge in any language other than English.</div>

            <h2>When do new Rootls become available?</h2>
            <div>Every day at midnight GMT.</div>

            <h2>Why are all the words 5-15 letters long?</h2>
            <div>In part, this is to keep the game from being too hard. It also allowed me to trim the dictionary, keeping the overall file size a little more manageable.</div>

            <h2>Does Rootl support British/international spellings?</h2>
            <div>It makes more sense to me to standardize on a single version of spellings. With apologies to my friends around the world who consider the American spellings a travesty, I'm just using American English. This doesn't stop me from including words which might be considered culturally 'British.'</div>

            <h2>I got all the letters right, but not the correct word. Even so, Rootl tells me I won. What's up with that?</h2>
            <div>Originally, the rule was that you had to guess the precise word. A number of players suggested that it made sense to just give you credit if you found all the correct letters, so I went with that.</div>

            <h2>What does ____ mean? (a list of potentially confusing terms I use, 'cause this is all educational, dammit!)</h2>
            <h3>Eponymous</h3>
            <div>An eponym is a person after whom something is named, and a word is called 'eponymous' if it derives from an eponym. For example, a sandwich is eponymous for the Earl of Sandwich, and a teddy bear is eponymous for Theodore Roosevelt.</div>
            
            <h3>Neologism</h3>
            <div>A neologism is a coined word or expression. It might remind us of older words, but it was invented to fill a specific, new meaning. Examples include <em>robot</em> (coined for the 1920 play R.U.R.), <em>laser</em> (from the acronym Light Amplification by Stimulated Emission of Radiation), and <em>emoji</em> (a portmanteau from the Japanese words 'e', meaning 'picture' + 'moji' meaning 'character'). </div>
            
            <h3>Proto-Indo-European</h3>
            <div>One of the coolest and craziest facts in all of etymology (at least to me) is that nearly all languages from India across the Middle East and as far as Western Europe derive from a common root. This root language (perhaps the most Rootl of all languages!) was once called 'Aryan' until some unsavory characters in the twentieth century dragged that term through the mud. The current term to describe this common ancestor is <Link href='https://en.wikipedia.org/wiki/Proto-Indo-European_language' target='_blank' >Proto-Indo-European</Link>. While no one can say for sure exactly when, where, and how it was spoken, this 'mother tongue' has had a profound impact on how much of the world expresses itself.</div>

            <Button style={{margin:'0 .5rem'}} variant="contained" onClick={() => handleMenuClick(null, "game")}>
                <Typography variant="button">PLAY GAME</Typography>
            </Button>
        </div>
    );
}

export default Faq;