import './Stats.css';
import React, { useEffect, useState } from 'react';
import { rootlCookieName } from '../../Utilities/RootlCookieProvider';
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemIcon,
        ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from  '@mui/icons-material/Share';
import { useCookies } from 'react-cookie';
import Challenge from '../Challenge/Challenge';
import { getGameHistory } from '../../Utilities/LetterFinder';
import { demungeGameData } from '../../Utilities/GameDataMunger';
import { currentGameState } from '../../Utilities/ScoreUtility';

/// Responsible for the display of the users win ratio and other stats
/// Also has a button for deleting the users cookies

/// allData: The object model that controls the app
/// gameData: The object model that controls the game
/// allCookies: Object - contains the cookie key/value pairs. NB: this is injected via App as a workaround for a bug in react-cookies
const Stats = ({ appData, gameData, allCookies, analyticsEvent, handleClose, handleToasterMessage }) => {

    const [, , removeCookie] = useCookies([rootlCookieName]);
    const challengeIdentity = {
        message: "",
        title: ""
    };

    const [gameType, setGameType] = useState("");

    useEffect(() => {
        const currentGameIsStreakGame = appData.currentDate === appData.cursorDate;
        setGameType(currentGameIsStreakGame ? "streak" : "hobby");
    }, [setGameType, appData]);

    const [challengeData, setChallengeData] = useState(challengeIdentity);

    function getLatestWin() {
        const ds = allCookies.latestWinDate;
        let retv = "No wins yet"
        if (ds !== undefined) {
            const d = new Date();
            retv = d.toLocaleDateString(undefined, { day:"numeric", year:"numeric", month:"short"});
        }
        return retv;
    }

    function clearCookie () {
        
        removeCookie("currentAppPage", {path:"/"});
        removeCookie("mode", {path:"/"});
        removeCookie("latestWinDate", {path:"/"});
        removeCookie("currentStreak", {path:"/"});
        removeCookie("maxStreak", {path:"/"});
        removeCookie("totalWins", {path:"/"});
        removeCookie("numPlays", {path:"/"});
        removeCookie("bestScore", {path:"/"});
        removeCookie("averageScore", {path:"/"});
        removeCookie("streakGameData", {path:"/"});
        removeCookie("hobbyGameData", {path:"/"});
        
        handleToasterMessage("Your data has been reset", document.getElementsByClassName("rootl")[0], true);
        
        analyticsEvent({
            'event': "clear_cookies"
        });
    }

    function challengeClearCookie() {
        setChallengeData({
            message: "This will remove your history of play with us, including all high score and streak information. Note that this data is all stored locally on your own computer.",
            title: "Are you sure you want to clear your data?",
            confirmHandler: clearCookie,
            id: "clear_cookies"
          });
    }

    const handleGameType = (value) => {
        setGameType(value);
    }


    const handleShare = (event) => {
        // build the sharable content
        let shareString = "Rootl game #" + gameData.gameId + "\n\n";
        let resultString = getGameHistory(gameData.levelData);

        //TODO: put real markers in here
        resultString = resultString.replace(/[A-Z]/g, "🟩");
        resultString = resultString.replace(/[ ]/g, "⬛");

        shareString += resultString;
        
        // copy
        navigator.clipboard.writeText(shareString);

        // inform the user
        handleToasterMessage("Copied to clipboard", event.target, true);

        // Analytics: 
        analyticsEvent({
            'event': "share_button_clicked",
            'mode': appData.mode,
            'levels_successful':  successes
        });
    }

    const latestPlay = demungeGameData(gameType === 'hobby' ? allCookies.hobbyGameData : allCookies.streakGameData);
    const latestPlayState = currentGameState(latestPlay.levelData, appData);
    const isCurrentGameComplete = latestPlayState.isComplete;
    let todaysGameListItems = [];
    todaysGameListItems.push(
        <ListItem key="todaysGame">
            <h2>
                {gameType === "streak" ? "Today's game" : "Game #" + latestPlay.gameId}
                {isCurrentGameComplete ? '' : ': Incomplete'}
            </h2></ListItem>
    );
    todaysGameListItems.push(
        <ListItem key="totalScore"><h3><span className="totalScore">Total score:</span>&nbsp;{latestPlayState.score}</h3></ListItem>
    );
    todaysGameListItems.push(
        <ListItem key="share" className="share">
            <Button className="statsButton" 
                aria-label="Share game"
                variant="contained" 
                onClick={handleShare}
                endIcon={<ShareIcon/>}> Share
            </Button>
        </ListItem>
    );


    let successes = 0;
    
    if (isCurrentGameComplete) {
        for (let a = 0; a < latestPlay.levelData.length; a++) {
            const element = latestPlay.levelData[a];
            const success = element.success === 2;
                todaysGameListItems.push (
                    <ListItem key={a} className={success ? "success" : "failure"}> 
                        <ListItemIcon className="outcomeIcon">
                            { success ? <SentimentSatisfiedAltIcon fontSize="small" /> : <SentimentVeryDissatisfiedIcon fontSize="small" />}
                        </ListItemIcon>
                        <ListItemText>{element.answer}<br />
                        <span className="levelPointsInStats">for {latestPlayState.scores[a]} points</span></ListItemText>
                    </ListItem>);
            if (success) {
                successes++;
            }
        }
        
    }

    function createStatsData(stat, value) {
        return { stat, value };
      }
      
    const rows = [
    createStatsData('Latest Win', getLatestWin()),
    createStatsData('Best win streak', allCookies.maxStreak || "No wins yet"),
    createStatsData('Current win streak', allCookies.currentStreak || "No wins in current streak"),
    createStatsData('Best score', allCookies.bestScore || "No wins yet"),
    createStatsData('Average score', Math.round(allCookies.averageScore) || "No wins yet"),
    createStatsData('Total wins', allCookies.totalWins || "No wins yet"),
    createStatsData('Total plays', allCookies.numPlays || "No plays yet"),
    ];

    return (
        <Dialog
            className="statsWindow"
            open={appData.currentAppPage === 'stats'}
            onClose={handleClose}
        >
            <DialogTitle className="modalHeader">
                <Box>
                    <Box >Your Rootl Stats</Box>
                    <Box>
                        <IconButton className="closeButton" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

            </DialogTitle>
            <DialogContent>
                <Challenge title={challengeData.title} message={challengeData.message}  id={challengeData.id}
                        confirmHandler={challengeData.confirmHandler} handleClearChallenge={() => {setChallengeData(challengeIdentity)}} />
                <div className='stats'>
                    <ButtonGroup className="gameType"
                        aria-label="Result selector" >
                        <Button key="streak" value="streak" 
                            variant={gameType === 'streak' ? "contained" : "outlined"} 
                            onClick={() => handleGameType('streak')}
                            aria-label="today">Today's game
                        </Button>
                        <Button key="hobby" value="hobby" 
                            variant={gameType === 'hobby' ? "contained" : "outlined"} 
                            onClick={() => handleGameType('hobby')}
                            aria-label="hobby">Hobby game
                        </Button>
                    </ButtonGroup>

                    <div className='todaysGame'>
                        <List aria-label="Outcome of today's game">{todaysGameListItems}</List>
                    </div>

                    <TableContainer component={Paper}>
                            <Table aria-label="Stats table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={2}><h2>Your Personal Stats</h2></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.stat}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.stat}
                                </TableCell>
                                <TableCell align="left">{row.value}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
    
                    <Button className="statsButton" 
                        id="dataResetButton"
                        variant="contained" 
                        color="error"
                        aria-label='Clear game data'
                        onClick={challengeClearCookie}>Reset my data</Button>

                </div>
            </DialogContent>
        </Dialog>
    );
  };

  export default Stats;