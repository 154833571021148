import { getDisplayLetters, getFoundLetters, letterGuessedAtTurn } from '../../../Utilities/LetterFinder';
import './ResultSpace.css'
import './OutcomeAnimation.css'
import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';

function ResultSpace({ appData, gameData, handleSelection, revealDelayMS }) {
    const level = gameData.levelData[gameData.currentLevelId];
    const output = [];

    const [displayRevision, setDisplayRevision] = useState(0);

    // This string is used for analysis
    let foundLetters = getFoundLetters(level.answer, level.guesses);
    // This string is what gets displayed
    let displayLetters = getDisplayLetters(foundLetters, appData.mode, level.success);

    const getClass = (letter) => {
        const isBlank = letter === " " || letter === ".";
        let success = isBlank ? "" : " success ";
        if (level.success  > 0) {
            success += ' levelComplete ';
        } else if (level.success === 0 && foundLetters.indexOf(" ") === -1) {
            success += ' allLettersFound ';
        } else if (letterGuessedAtTurn(letter, level.guesses) >= displayRevision) {
            success = ' waiting ';
        }
        return 'letterTile' + success;
    }

    const getStyle = (a) => {
        let success = "";
        if (level.success === 1 || level.success === 2) {
            success = {'animationDelay':(a*.1) +"s"};
        } else {
            success = {'animationDelay':.1 +"s"}
        }
        return success;
    }

    const getContent = (index, letter) => {
        if (level.success === 0) {
            return letter === ' ' ? "\u00A0" : letter;
        } else {
            return letter === ' ' ? level.answer[index] : letter;
        }
    }

    for (let a = 0; a < displayLetters.length; a++) {
        const letter = displayLetters[a];
        output.push(
            <Paper key={a} className={getClass(letter)} style={getStyle(a)}>
                {getContent(a, letter)}
            </Paper>);
    }
            
       

    useEffect(() => {
        const interval = setTimeout(() => {
          setDisplayRevision(level.guesses.length)
        }, revealDelayMS);
        return () => clearTimeout(interval);
      }, [level.guesses, displayRevision, revealDelayMS]);

    return (
        <>
            <div id='foundLetters' className='resultSpace' onClick={handleSelection}>
                { output }
            </div>
            <div className="letterCount">({level.answer.length} letters)</div>
        </>
    );
}

export default ResultSpace;