import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';

function Announcement({appData, gameData, handleToasterMessage, analyticsEvent}) {
    const [currentAnnouncement, setCurrentAnnouncement] = useState({});
    
    useEffect(() => {
            // Pick a banner announcement for the current play
        const currentAnnouncementPick = () => {
            // find a special event if there is one
            if (gameData.imgUrl !== undefined && gameData.imgUrl !== "") {
                const specialEvent = {
                    imgUrl: gameData.imgUrl,
                    alt: gameData.alt,
                    link: gameData.link
                }
                return specialEvent;
            }

            // if no special event, pick a random standing banner
            const num = Math.floor(Math.random() * appData.announcements.standing.length);
            const standingEvent = appData.announcements.standing[num]
            return standingEvent;
        }

        setCurrentAnnouncement(currentAnnouncementPick())
      }, [setCurrentAnnouncement, gameData.gameId, gameData.imgUrl, gameData.alt, gameData.link, appData]);

    const logClick = (id) => {
        analyticsEvent({
            "event": "visited_" + id,
        });
    }

    const share = async (event) => {
        await navigator.clipboard.writeText("https://www.rootlgame.net/");
        logClick("share");
        handleToasterMessage("Link copied to clipboard", event.target, true);
    }

    let gLink = () => {
        if (currentAnnouncement.link === undefined || currentAnnouncement.link.length === 0) {
            return  (<Link onClick={(e) => share(e)} style={{ cursor: 'pointer' }}>
                        <img className='announcement' src={'./images/' + currentAnnouncement.imgUrl} alt={currentAnnouncement.alt} />
                    </Link>);
        } else {
            return  (<Link href={currentAnnouncement.link} target="_blank">
                        <img className='announcement' src={'./images/' + currentAnnouncement.imgUrl} alt={currentAnnouncement.alt} />
                     </Link>);
        }
    }

    return (
        <>
            { gLink() }
        </>
    );
  }

  export default Announcement;