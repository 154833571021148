import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Challenge({ title, message, confirm, cancel, confirmHandler, handleClearChallenge }) {
    const [open, setOpen] = React.useState(false);
    confirm = confirm ? confirm : 'Confirm';
    cancel = cancel ? cancel : 'Cancel';

    const handleClose = () => {
        handleClearChallenge();
        setOpen(false);
    };

    const handleConfirm = () => {
        handleClose();
        confirmHandler();
    }

    if (message !== "" && !open) {  
        setOpen(true);
    }

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="cancel" onClick={handleClose}>{cancel}</Button>
          <Button id="confirm" 
            color="error" variant='contained'
            onClick={handleConfirm}>{confirm}</Button>
        </DialogActions>
      </Dialog>
  );
}