
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { buildMatchesArray } from '../../../Utilities/LetterFinder';
import './LetterDeployment.css';
import './ResultSpace.css';
import { Paper } from '@mui/material';

function LetterDeployment({ appData, gameData, timingConfig }) {
    const [isAnimating, setIsAnimating] = useState(false);
    const level = gameData.levelData[gameData.currentLevelId];
    const latestGuess = level.guesses.length === 0 ? "" : level.guesses[0];

    const matchArray = useRef(buildMatchesArray(latestGuess, level.answer) || []);

    // Effect to deactivate this component
    useEffect(() => {
        let t = timingConfig.totalWaitMS;

        if (t > 0) {
            const interval = setTimeout(() => {
                setIsAnimating(false);
            }, t);
            return () => clearTimeout(interval);
        }
    }, [timingConfig, isAnimating]);

    // Effect to kick off the animation after construction
    useLayoutEffect(() => {
        const findElementFromFoundLetters = (element, a) => {
            const rootElement = document.getElementById('foundLetters');
            let destElement;
            if (appData.mode === "normal") {
                destElement = rootElement.childNodes[a] ?? rootElement;
            } else {
                destElement = rootElement;
            }
            return destElement;
        }
    
        const setAnimations = (matchArray) => {
            let iter = 0;
            matchArray.map((element, a) => {
                for (let b = 0; b < element.instances.length; b++) {
                    let style = {};

                    const sourceElement = document.getElementById("letterDeploy" + iter);
                    const sourceRect = sourceElement.getBoundingClientRect();
                    const sourceX = sourceRect.x + sourceRect.width/2;
                    const sourceY = sourceRect.top;

                    const startX = (sourceRect.width + 3) * a;
                    const startY = 0;

                    let finalX, finalY = 0;

                    switch (element.outcome) {
                        case 'spare':
                            finalX = startX;
                            finalY = startY;
                            break;
                        default:
                            let destElement = (element.outcome === 'match') ? findElementFromFoundLetters(element, element.instances[b]) :
                                document.getElementById('missedLetters');
                            
                            const destRect = destElement.getBoundingClientRect();

                            const destX = destRect.left + destRect.width/2;
                            const destY = destRect.top;

                            finalX = destX - sourceX;
                            finalY = destY - sourceY + (destRect.height-sourceRect.height)/2;
                    }


                    sourceElement.animate([
                        // key frames
                        {
                            transform: 'translate(' + startX +'px, ' + startY + 'px) scale(1)', 
                            opacity: 1,
                            offset: 0 },
                        {
                            transform: 'translate(' + (finalX) + 'px, ' + (finalY) + 'px) scale(.2)', 
                            opacity: .9,
                            offset: .9 },
                        {
                            transform: 'translate(' + (finalX) + 'px, ' + (finalY) + 'px) scale(1)', 
                            opacity: 0,
                            offset: 1  }
                    ],
                    {
                        id: 'letterDeploy',
                        duration: timingConfig.perLetterAnimMS,
                        iterations: 1,
                        delay: timingConfig.initDelayMS + iter * timingConfig.perLetterDelayMS,
                        fill: "both",
                        easing: "ease-out"
                    }
                    );
                    
                    sourceElement.style = style;
                    iter++;
                }
                return null;
            });
        }

        // When wait time is set > 0, it's the result of a bona fide guess.
        // Everything else is just general updates of state.
        if (timingConfig.totalWaitMS > 0) {
            setIsAnimating(true);

            // Ensure we update the state of the matchArray
            // If we don't, old data will get cached
            matchArray.current = buildMatchesArray(latestGuess, level.answer) || [];
            const interval = setTimeout(() => {
                window.requestAnimationFrame(() => {
                    setAnimations(matchArray.current);
                })
            });
            return () => {
                clearTimeout(interval);
            }
        }
    }, [latestGuess, level.answer, matchArray, timingConfig, appData.mode, isAnimating]);

    let iter = 0;
    return (
        <div id="letterDeployment" className="letterDeployment">
            { 
                matchArray.current.map((element, a) => {
                    const t = [];
                    for (let b = 0; b < element.instances.length; b++) {
                        if (isAnimating) {
                            t.push(
                            <Paper id={"letterDeploy"+iter} key={"letterDeploy"+iter} 
                                className={"letterTile letterDeploy " + element.outcome} 
                                style={{
                                    animationName:"none"}}>
                                {element.letter}
                            </Paper>);
                            iter++;
                        }
                        
                    }
                    return t;
                })
            }
        </div>
    );
}

export default LetterDeployment;