import { Paper } from '@mui/material';
import { getMissedLetters, letterGuessedAtTurn } from '../../../Utilities/LetterFinder';
import { useEffect, useState } from 'react';
  
  export default function MissedLetters({ appData, gameData, handleSelection, revealDelayMS }) {
    
    const level = gameData.levelData[gameData.currentLevelId];
    
    const output = [];
    const [displayRevision, setDisplayRevision] = useState(0);
    const allMissedLetters = getMissedLetters(level.answer, level.guesses);

    for (let a = 0; a < allMissedLetters.length; a++) {
      const letter = allMissedLetters[a];
      if (appData.mode !== 'beast') {
        output.push(<Paper className={displayRevision > letterGuessedAtTurn(letter, level.guesses) ? "usedLetter" : "hidden"} key={letter}>{letter}</Paper>);
      }
    }
    
    useEffect(() => {
      const interval = setTimeout(() => {
        setDisplayRevision(level.guesses.length)
      }, revealDelayMS);
      return () => clearTimeout(interval);
    }, [level.guesses, displayRevision, revealDelayMS]);

    return(
      <>
        <span id='missedLetters' onClick={handleSelection} className="usedLetters">{output}</span>
      </>
      );
    }